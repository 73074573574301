export default function AgreePrivacy(){
  const txt = `<개인정보 수집 및 이용동의>*

  당사는 '개인정보보호법'에 따라 귀하의 개인정보를 다음과 같이 수집.이용하고자 합니다.
  
  개인정보의 수집.이용 목적
  - 회원관리 및 결혼서비스에 관한 상담 및 자료요청 의사 확인
  - 결혼관련 서비스 상담 및 이용 권유, 각종 서비스 및 이벤트 안내
  - 테스트 결과 도출 및 안내를 위한 정보수집
  - 이벤트 참가신청, 참가가능여부, 당첨자발표, 진행사항에 대한 정보 전달
  
  수집하는 개인정보의 항목
  - 성명, 출생년도(또는 출생년월일), 성별, 연락처 및 휴대전화번호, 결혼경력, 이메일, 학력(또는 최종출신학교), 주거주지(또는 주소), 직업종류(또는 직장명)
  
  개인정보의 보유 및 이용기간
  - 결혼중개업법 기준 5년 또는 개인정보 삭제 및 탈회를 요청할 때까지 보유.이용합니다.
  - 결혼회원 가입의 경우 개인정보에 관한 회사 내부 방침에 따라 개인정보를 보유합니다.
  - 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
  
  가. 회사 내부 방침에 의한 정보보유 사유
  
  -개인정보 삭제 및 회원탈퇴 신청기록
    보존 이유 : 부정 이용 방지
    보존 기간 : 우연 회원 탈퇴일부터 2년
    보존 항목 : 아이디, 이름, 출생년도, 이메일
  
  -채용에 관한 입사지원 기록
    보존 이유 : 상시 채용 및 내부 인재풀 관리
    보존기간 : 최종 업데이트일부터 180일
  
  나. 관련법령에 의한 정보보유 사유
  
  상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
  
  - 계약 또는 청약철회 등에 관한 기록
    보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
    보존 기간 : 5년
  
  - 대금결제 및 재화 등의 공급에 관한 기록
    보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
    보존 기간 : 5년
  
  - 전자금융 거래에 관한 기록
    보존 이유 : 전자금융거래법
    보존 기간 : 5년
  
  - 소비자의 불만 또는 분쟁처리에 관한 기록
    보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
    보존 기간 : 3년
  
  - 본인 확인에 관한 기록
    보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률
    보존 기간 : 6개월
  
  - 웹사이트 방문기록
    보존 이유 : 통신비밀보호법
    보존 기간 : 3개월
  
  
  개인정보의 수집 및 이용에 대한 동의 거부 관련
  - 당사는 서비스 제공에 필요한 최소한의 정보만을 수집·이용합니다.
  - 이용자는 개인정보의 수집 및 이용 동의에 거부하실 수 있으며, 다만 이 경우 해당 서비스 이용 및 가입상담이 제한될 수 있습니다.`;
  return(
    <pre>{txt}</pre>
  )
}